<template>
	<div class="no404">
		<img class="errimg" src="../assets/img/500.svg" >
		<h4>抱歉，服务器好像出错了哦</h4>
	</div>
</template>

<script>
	export default {
		name: 'err',
	}
</script>

<style lang="scss">
	.no404{
		height: 100vh;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		.errimg{
			width: 300px;
			height: 300px;
		}
	}
</style>
